<template>
    <r-e-dialog title="更换电表" class="layer-contract-detail" :visible.sync="dialogVisible"
                show-footer top="10vh" @click-submit="clickSubmit" @click-cancel="clickCancel" @close="clickCancel"
                width="1200px">
        <div class="flex justify-between align-center flex-warp"
            style="
                background-color: #eff9ff;
                height: 50px;
                color: #333333;
                font-size: 15px;
                margin-bottom:15px
        ">
            <div style="margin-left: 20px">房间号 : {{ equipmentData.name }}</div>
        </div>  
        <div class="flex" >
            <div style="border-left: #2a8af7 5px solid; padding-left: 5px; width:65%">
                旧电表 
            </div>
            <div style="border-left: #2a8af7 5px solid; padding-left: 5px; width:22%">
                新电表
            </div>
        </div>      
        <el-form ref="formPublish" label-width="120px" size="small" :model="hydropowerData" :rules="rules">
            <div class="flex">
                <el-form-item label="旧表号">
                    <el-input v-model="hydropowerData.oldMeterNum" style="width: 250px;" disabled/>
                </el-form-item>
                <el-form-item label="抄表类型" prop="type">
                    <el-radio-group v-model="hydropowerData.type" style="width: 250px;">
                        <el-radio-button :label="1">手动</el-radio-button>
                        <el-radio-button :label="2">自动</el-radio-button>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="新表号" prop="deviceNumber">
                    <el-input v-model="hydropowerData.deviceNumber" placeholder="请填写新表号" style="width: 250px;"/>
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="上次抄表时间">
                    <el-date-picker v-model="hydropowerData.lastTime" type="datetime" placeholder="选择日期时间" disabled
                                    style="width: 250px;"/>
                </el-form-item>
                <el-form-item label="上次抄表度数">
                    <el-input type="number" v-model="hydropowerData.last" style="width: 250px;" disabled/>
                </el-form-item>
                <el-form-item label="新表厂家" prop="meterType">
                    <el-select  v-model="hydropowerData.meterType" placeholder="请选择厂家" style="width: 250px">
                        <el-option label="魔方" :value="0"></el-option>
                        <el-option label="租宝" :value="1"></el-option>
                        <el-option label="东润" :value="2"></el-option>
                        <el-option label="智慧仪表" :value="3"></el-option>
                        <el-option label="慧明谦" :value="4"></el-option>
                    </el-select>
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="旧表结束度数" prop="current">
                    <el-input type="number" v-model="hydropowerData.current" placeholder="请填写旧表结束度数"
                              style="width: 250px;"/>
                </el-form-item>
                <el-form-item label="单价">
                    <el-input type="number" v-model="hydropowerData.price" style="width: 250px;" disabled/>
                </el-form-item>
                <el-form-item label="新表型号" prop="mountingsName">
                    <el-input v-model="hydropowerData.mountingsName" placeholder="请填写新表型号" style="width: 250px;"/>
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="旧表厂家" prop="meterType">
                    <el-select  v-model="hydropowerData.meterType" placeholder="请选择厂家" style="width: 250px">
                        <el-option label="魔方" :value="0"></el-option>
                        <el-option label="租宝" :value="1"></el-option>
                        <el-option label="东润" :value="2"></el-option>
                        <el-option label="智慧仪表" :value="3"></el-option>
                        <el-option label="慧明谦" :value="4"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="旧表型号" prop="oldMountingsName">
                    <el-input v-model="hydropowerData.oldMountingsName" placeholder="请填写旧表型号" style="width: 250px;"/>
                </el-form-item>
                <el-form-item label="新表初始度数" prop="current">
                    <el-input type="number" v-model="hydropowerData.current" placeholder="请填写新表初始度数"
                              style="width: 250px;"/>
                </el-form-item>
            </div>
            <div class="flex" >
                <div style="border-left: #2a8af7 5px solid; padding-left: 5px; width:28%">
                    维修员信息 
                </div>
            </div>  
            <div class="flex">
                <el-form-item label="维修员姓名" prop="maintainMan">
                    <el-input v-model="hydropowerData.maintainMan" placeholder="请填写维修员姓名" style="width: 250px;"/>
                </el-form-item>
                <el-form-item label="维修员手机号" prop="maintainPhone">
                    <el-input v-model="hydropowerData.maintainPhone" placeholder="请填写维修员手机号" style="width: 250px;"/>
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="换表前照片" prop="beforeImg" style="width: 180%;">
                    <upload-picture-card :uuidList="beforeImgList" :limit="24" @on-success="beforeImgListHandle"
                                         @on-remove="beforeImgListHandle"/>
                </el-form-item>
                <el-form-item label="换表后照片" prop="afterImg" style="width: 100%;">
                    <upload-picture-card :uuidList="afterImgList" :limit="24" @on-success="afterImgHandle"
                                         @on-remove="afterImgHandle"/>
                </el-form-item>
            </div>
        </el-form>
    </r-e-dialog>
</template>

<script>
import {MessageInfo, MessageError, MessageSuccess} from "@custom/message";
import {getDeviceInfo, exchangeDevice} from "@/api/door-management";
import {timeFormat, ParseFloatFormat} from "@custom/index";

export default {
    name: "dialog-replace-hydropower",
    data() {
        return {
            dialogVisible: false,
            hydropowerData: {
                apartmentUuid: null, //房间uuid
                apartmentName: null,  //房间名称
                type: null, //抄表类型
                deviceType: null, //表类型
                last: null, //上次抄表度数
                current: null,  //旧表结束度数
                lastTime: null, //上次抄表时间
                dataSources: null,  //数据来源
                meterType: null,  //厂家
                deviceNumber: null, //新表号
                newStartNum: null,  //新表初始度数
                newCurrentNum: null,  //新表当前度数（本次抄表金额）
                beforeImg: null,  //换表前照片（旧表照片）
                afterImg: null, //换表后照片（新表照片）
                maintainPhone: null,  //维修人员手机号
                maintainMan: null,  //维修人姓名
                oldMountingsName: null, //旧表型号
                oldMountingsBrand: null, //旧表品牌
                mountingsName: null, //新表型号
                mountingsBrand: null, //	新表品牌
            },
            rules: {
                type: [{required: true, message: '请选择抄表类型', trigger: 'change'}],
                current: [{required: true, message: '请填写旧表结束度数', trigger: 'blur'}],
                dataSources: [{required: true, message: '请选择数据来源', trigger: 'change'}],
                meterType: [{required: true, message: '请选择厂家', trigger: 'change'}],
                deviceNumber: [{required: true, message: '请填写新表号', trigger: 'blur'}],
                newStartNum: [{required: true, message: '请填写新表初始度数', trigger: 'blur'}],
                newCurrentNum: [{required: true, message: '请填写新表当前度数', trigger: 'blur'}],
                beforeImg: [{required: true, message: '请上传换表前照片', trigger: 'change'}],
                afterImg: [{required: true, message: '请上传换表后照片', trigger: 'change'}],
                maintainMan: [{required: true, message: '请填写维修员姓名', trigger: 'blur'}],
                maintainPhone: [{required: true, message: '请填写维修员手机号', trigger: 'blur'}],
                oldMountingsName: [{required: true, message: '请填写旧表型号', trigger: 'blur'}], //旧表型号
                oldMountingsBrand: [{required: true, message: '请填写旧表品牌', trigger: 'blur'}], //旧表品牌
                mountingsName: [{required: true, message: '请填写新表型号', trigger: 'blur'}], //新表型号
                mountingsBrand: [{required: true, message: '请填写新表品牌', trigger: 'blur'}], //	新表品牌
            },
            type: "",
            loadingOptions: {
                lock: true,
                text: "Loading...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)"
            },
            beforeImgList: [],
            afterImgList: [],
        };
    },
    components: {
        uploadPictureCard: () => import("@/components/Upload/upload-picture-card"),
    },
    props: {
        equipmentData: {
            type: Object,
            default: () => ({})
        }
    },
    methods: {
        openDialog(type) {
            let that = this;
            this.type = type;
            const {apartmentUuid} = this.equipmentData;
            this.hydropowerData.apartmentUuid = apartmentUuid;
            const loadingOptions = that.loadingOptions;
            const loading = that.$loading({...loadingOptions});
            const oldData = that.hydropowerData;
            getDeviceInfo({deviceType: type === 'Water' ? '水表' : '电表', apartmentUuid}).then(res => {
                const {info} = res;
                let {price, type = null} = info;
                type = type === 0 ? null : type;
                price = ParseFloatFormat(price);
                that.hydropowerData = {...oldData, ...info, price, type};
                that.dialogVisible = true;
            }).finally(() => loading.close());
        },

        clickSubmit() {
            let that = this;
            this.$refs["formPublish"].validate((valid) => {
                if (valid) {
                    let {
                        apartmentUuid, type, deviceType, last, current, lastTime, dataSources, meterType, deviceNumber,
                        newStartNum, newCurrentNum, beforeImg, afterImg, maintainPhone, maintainMan, oldMountingsName,
                        oldMountingsBrand, mountingsName, mountingsBrand,
                    } = that.hydropowerData;
                    let data = {
                        apartmentUuid, type, deviceType, last, current, lastTime, dataSources, meterType, deviceNumber,
                        newStartNum, newCurrentNum, beforeImg, afterImg, maintainPhone, maintainMan, oldMountingsName,
                        oldMountingsBrand, mountingsName, mountingsBrand,
                    };
                    const loadingOptions = that.loadingOptions;
                    const loading = that.$loading({...loadingOptions});          
                    exchangeDevice(data).then(res => {
                        this.$emit('confirm');
                        MessageSuccess("更换成功");
                        that.clickCancel();
                    }).finally(() => loading.close());
                }
            });
        },

        clickCancel() {
            this.dialogVisible = false;
            this.hydropowerData = {
                apartmentUuid: null, //房间uuid
                apartmentName: null,  //房间名称
                type: null, //抄表类型
                deviceType: null, //表类型
                last: null, //上次抄表度数
                current: null,  //旧表结束度数
                lastTime: null, //上次抄表时间
                dataSources: null,  //数据来源
                meterType: null,  //厂家
                deviceNumber: null, //新表号
                newStartNum: null,  //新表初始度数
                newCurrentNum: null,  //新表当前度数（本次抄表金额）
                beforeImg: null,  //换表前照片（旧表照片）
                afterImg: null, //换表后照片（新表照片）
                maintainPhone: null,  //维修人员手机号
                maintainMan: null,  //维修人姓名
            };
        },

        afterImgHandle({fileList}) {
            this.afterImgList = fileList.map(item => {
                let {name, response} = item;
                let resOk = response && typeof (response) !== undefined;
                if (resOk) {
                    let {returnObject: {info: {uuid}}} = response;
                    return uuid;
                } else return name;
            });
            this.hydropowerData.afterImg = this.afterImgList.join(";");
            this.$refs["formPublish"].validateField("afterImg");
        },

        beforeImgListHandle({fileList}) {
            this.beforeImgList = fileList.map(item => {
                let {name, response} = item;
                let resOk = response && typeof (response) !== undefined;
                if (resOk) {
                    let {returnObject: {info: {uuid}}} = response;
                    return uuid;
                } else return name;
            });
            this.hydropowerData.beforeImg = this.beforeImgList.join(";");
            this.$refs["formPublish"].validateField("beforeImg");
        },
    },
}
</script>

<style scoped>
/deep/ .el-dialog--center .el-dialog__body{
    padding:15px 25px 20px 
}
</style>